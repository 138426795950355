// @flow
import { buildOptions, makeRequest } from './ServiceHelpers';
import { auth } from '../utils/auth';
import get from 'lodash/get';
import { permissions } from '../constants/permissions';
import { CLAIM_USER_CANONICAL_ID } from '../constants/appConstants';

const endpointUrl = process.env.REACT_APP_COAM_URL;

export const fetchIdentityPermissions = () => {
  const options = buildOptions();
  const profile = auth.getProfile();
  const sub = profile[CLAIM_USER_CANONICAL_ID]

  return makeRequest(endpointUrl, `/principals/${sub}/permissions`, options, 'fetching coam permissions').then(
    permissions => {
      let fulfillerPermissionMap = {};
      let merchantPermissionMap = {};

      // If we got any permissions back
      if (permissions) {
        if (permissions.fulfillers) {
          fulfillerPermissionMap = permissions.fulfillers.reduce((map, { identifier, permissions }) => {
            map[identifier] = permissions;
            return map;
          }, {});
        }

        // If we got merchant permissions
        if (permissions.merchants) {
          merchantPermissionMap = permissions.merchants.reduce((map, { identifier, permissions }) => {
            map[identifier] = permissions;
            return map;
          }, {});
        }
      }

      return {
        fulfillerPermissionMap,
        merchantPermissionMap
      };
    }
  );
};

export const fetchUserProfile = userSub => {
  const options = buildOptions();

  return makeRequest(
    endpointUrl,
    `/principals/${encodeURI(userSub)}?responseFilter=groups%2Cpermissions&include=false`,
    options,
    'fetching user profile'
  ).then(res => get(res, 'profile.email', userSub));
};

export const fetchAccountPermissions = () => {
  const options = buildOptions();

  return makeRequest(
    endpointUrl,
    `/principals/self/permissions/account?include=true&permissionFilter=${encodeURI(
      'user:contract-manager'
    )},${encodeURI('admin:contract-manager')}`,
    options,
    'fetching coam permissions'
  ).then(permissions => {
    // If we got any permissions back
    if (permissions && permissions.length > 0) {
      return permissions[0].permissions;
    } else {
      return [];
    }
  });
};

export const fetchAccountWithPermissions = async () => {
  const options = buildOptions();

  return await makeRequest(
    endpointUrl,
    `/principals/self/permissions/account?include=true&permissionFilter=${encodeURI(
      permissions.MANAGE_CONTRACT
    )},${encodeURI(permissions.READ_CONTRACT)},${encodeURI(permissions.MANAGE_PRODUCT_AGREEMENT)},${encodeURI(
      permissions.READ_PRODUCT_AGREEMENT
    )}`,
    options,
    'fetching coam permissions'
  );
};
